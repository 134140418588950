import "./App.css";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";
import LandingScreen from "./screens/landingScreen";
import PaymentScreen from "./screens/paymentScreen";
import PaymentScreenNew from "./screens/paymentScreenV2";
import { APP_CONSTANTS, ENV } from "./services/app.const";
import ReactGA from 'react-ga4';
import MetaPixel from "./services/metaPixel";
import TagManager from 'react-gtm-module';

const ENV_VARIABLES = APP_CONSTANTS[ENV];
const GOOGLE_ANALYTICS_ID = ENV_VARIABLES.GOOGLE_ANALYTICS_ID;
const MICROSOFT_CLARITY_ID = ENV_VARIABLES.MICROSOFT_CLARITY_ID;
ReactGA.initialize(GOOGLE_ANALYTICS_ID, { debug: true });
const tagManagerArgs = {
  gtmId: APP_CONSTANTS[ENV].GOOGLE_TAG_MANAGER_ID,
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  React.useEffect(() => {
    document.querySelectorAll('a[href^="#"]')?.forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href"))?.scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  const GATracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID);
      TagManager.initialize(tagManagerArgs);
      setInitialized(true);
    }, []);
    useEffect(() => {
      if (initialized) {
        ReactGA.send(location.pathname + location.search);
        TagManager.dataLayer({
          dataLayer: {
            event: 'pageview',
            pagePath: location.pathname,
            pageLocation: window.location.href,
            pageTitle: document.title,
          },
        });
      }
    }, [initialized, location]);
  };


  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", MICROSOFT_CLARITY_ID);
  }, [MICROSOFT_CLARITY_ID]);



  return (
    <HelmetProvider>
      <div
        className="App"
        style={{ backgroundColor: "#fff", background: "#FFF", height: "100vh" }}
      >
        <Router>
        <MetaPixel />
        <GATracker />
          <Routes>
            <Route path="/" element={<LandingScreen />} />
            <Route path="/home" element={<LandingScreen />} />
            <Route path="/forex" element={<PaymentScreen />} />
            <Route path="/forexV2" element={<PaymentScreenNew />} />
          </Routes>
        </Router>
      </div>
    </HelmetProvider>
  );
};

export default App;
