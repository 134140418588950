export const APP_CONSTANTS = {
  development: {
    SERVICE_BASE_URL: "https://gtp-api.trafyn.info",
    MICROSOFT_CLARITY_ID: '1',
    GOOGLE_ANALYTICS_ID: 'G-RNNZWKHV6E',
    GOOGLE_TAG_MANAGER_ID: 'GTM-1242425',
  },
  production: {
    SERVICE_BASE_URL: "https://gtp-api.ninjacart.in",
    MICROSOFT_CLARITY_ID: 'ou5xso64aq',
    GOOGLE_ANALYTICS_ID: 'G-32VMW8XZNE',
    GOOGLE_TAG_MANAGER_ID: 'GTM-K46FX5LV',
  },
};


export const ENV = "production";
// export const ENV = "development";
