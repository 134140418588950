import React, { useEffect, useState, useRef } from "react";
import SEO from "../components/seo";
import paymentBannerImg from "../assets/images/headerImg.png";
import jpMorganLogo from "../assets/images/JPMorgan.svg";
import starIcon from "../assets/images/starIcon.svg";
import StepImage from "../assets/images/steps_img.png";
import flagIndia from "../assets/images/flag.jpg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import jpMorganLogoDark from "../assets/images/jpMorganDark.svg";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, fontFamily, height, positions } from "@mui/system";
import circleTick from "../assets/images/check_circle.png";
import sheild from "../assets/images/sheild.png";
import worldPng from "../assets/images/worldGroup.png";
import bankLogo from "../assets/images/bankLogo.png";
import fileLogo from "../assets/images/fileLogo.png";
import ContactLogo from "../assets/images/contactLogo.png";
import blueWorld from "../assets/images/worldGroupBlue.png";
import linkArrow from "../assets/images/upArrowIcon.png";
import carouselIMg from "../assets/images/carouselImg.png";
import upArrow from "../assets/images/upArrow.png";
import downArrow from "../assets/images/downArrow.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import successGif from "../assets/images/successGif.gif";
import { Select, MenuItem } from "@mui/material";
import ImageVss from "../assets/images/vss.svg";
import rights from "../assets/images/copyRights.png";
import AuthService from "../services/authService";
import USFlag from "../assets/images/usFlag.png";
// import profileAvatar from "../assets/images/profile_avatar.png";
import rightArrow from "../assets/images/arrowRightIcon.png";
import starWhite from "../assets/images/starWhite.png";
import FEDAIPdf from "../assets/docs/FEDAI_document.pdf";
import FIRAPdf from "../assets/docs/FIRA_documnent.pdf";
import { v4 as uuidv4 } from "uuid";
import playStoreImg from "../assets/images/google_play.png";
import appStoreImg from "../assets/images/app_store.png";
import { scroller, Element } from "react-scroll";
import novapayLogo from "../assets/images/novapayLogo.png";
import toast, { Toaster } from "react-hot-toast";
import { setFavicon } from "../services/utils/setFavicon";
import PaymentFavicon from "../assets/images/novapay.ico";
import tablebg from "../assets/images/tablebg.png";
import RBILOGO from "../assets/images/rbi.png";
import RBINEWLOGO from "../assets/images/rbi_logo.png";
import landingVideo from '../assets/images/novapay_yt.mp4';

const PaymentScreenNew = () => {
  const [selectedDate, setSelectedDate] = useState();
  const [currentTab, setCurrentTab] = useState(1);
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [receivedAmount, setReceivedAmount] = useState("");
  const [currencyValue, setCurrencyValue] = useState("");
  const [calculateMissionData, setCalculateMissionData] = useState(null);
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isValidPhnNumber, setIsValidPhnNumber] = useState(false);
  const [bankName, setBankName] = useState(null);
  const [createUserData, setCreateUserData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [deviceId, setDeviceId] = useState();
  const currentYear = new Date().getFullYear();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getDeviceId = () => {
    // Check if the device ID is already in local storage
    let storedDeviceId = localStorage.getItem('device_id');

    if (!storedDeviceId) {
      // If not, generate a new one and store it in local storage
      const newDeviceId = uuidv4();
      localStorage.setItem('device_id', newDeviceId);
      storedDeviceId = newDeviceId;
    }

    // Use the existing or newly created device ID
    setDeviceId(storedDeviceId);
  };

  const handleNameChange = (e) => {
    const value = e?.target?.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, ""); // Allows only letters and spaces
    setFullName(filteredValue);
  };

  const handleCompanyName = (e) => {
    setCompanyName(e?.target?.value);
  };

  const handleMobileNumber = (e) => {
    let value = e?.target?.value.toString();
    value = value?.replace(/\D/g, "");
    if (value?.length > 10) {
      value = value.slice(0, 10);
    }
    setMobileNumber(value);
    if (/^[1-5]/.test(value)) {
      setErrorMessage("Please enter a valid phone number");
      setIsValidPhnNumber(false);
    } else {
      setErrorMessage("");
      setIsValidPhnNumber(value?.length === 10 && /^[6-9]\d{9}$/.test(value));
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatDatevalue = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const maxDate = new Date();

  const handleTabChange = (tabNumber) => {
    setCurrentTab(tabNumber);
  };

  const bankList = [
    {
      _id: "1",
      name: "Axis Bank",
    },
    {
      _id: "2",
      name: "HDFC Bank",
    },
    {
      _id: "3",
      name: "ICICI Bank",
    },
    {
      _id: "4",
      name: "State Bank of India",
    },
    {
      _id: "4",
      name: "Kotak Mahindra Bank",
    },
    {
      _id: "4",
      name: "Punjab National Bank",
    },
    {
      _id: "4",
      name: "Bank of Baroda",
    },
    {
      _id: "4",
      name: "IDFC First Bank",
    },
    {
      _id: "4",
      name: "IDBI Bank",
    },
    {
      _id: "4",
      name: "Other",
    },
  ];

  const faqs = [
    {
      question: "1. How is this different from my current bank",
      answer:
        "Unlike traditional Indian banks, we provide you with a JP Morgan Chase Forex account,    offering both international credibility and 50% lower fees. Your clients send payments to a trusted US bank, while you receive funds in your Indian account",
    },
    {
      question: "2. How does it work?",
      answer:
        "Instead of your local Indian account, you share JP Morgan Forex account details with your overseas customers. Once payment is received in your JP Morgan account, it’ll get settled in your local Indian bank account automatically. So, your local trade processes remain unaffected. ",
    },
    {
      question: "3. Is this completely legal and compliant?",
      answer:
        "Absolutely. We're fully compliant with RBI regulations and international banking laws. All transactions are monitored and fully documented for your peace of mind. We also give you a FIRA for free for every settled transaction.",
    },
    {
      question: "4. How quickly can I start using my account?",
      answer:
        "Most accounts are activated within 24 hours of completing KYC. You can start accepting international payments immediately after activation.",
    },
    {
      question: "5. What is the account opening and maintenance fee?",
      answer:
        "Zero. Not just this, we do not charge for Swift fees, payouts/withdrawals, additional GST, or any other hidden charges. ",
    },
  ];

  const benefitsList = [
    {
      name: "Rakshitha Flowers",
      city: "Chennai",
      notes:
        "I am now receiving payments from my Malaysian customers at a 50% cheaper rate compared to the usual bank's rate. ",
    },
    {
      name: "Rakshitha Flowers",
      city: "Chennai",
      notes:
        "I am now receiving payments from my Malaysian customers at a 50% cheaper rate compared to the usual bank's rate. ",
    },
    {
      name: "Rakshitha Flowers",
      city: "Chennai",
      notes:
        "I am now receiving payments from my Malaysian customers at a 50% cheaper rate compared to the usual bank's rate. ",
    },
  ];
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailError, setEmailError] = useState(""); // Error state for email
  const [phoneError, setPhoneError] = useState(""); // Error state for phone number
  // const deviceId = uuidv4(); // Generate a unique device ID
  const [authToken, setAuthToken] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const footerRef = useRef(null);
  const calculatorRef = useRef(null);

  const handleScrollToBottom = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log("footerRef.current is null");
    }
  };

  const handleScrollTocalculator = () => {
    if (calculatorRef.current) {
      calculatorRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log("calculatorRef.current is null");
    }
  };

  const validateEmail = (email) => {
    // Simple regex for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Check if the phone number is exactly 10 digits
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleSubmit = async () => {
    let isValid = true;

    // Validate email
    if (!email) {
      setEmailError("Please enter your email address.");
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Validate phone number
    if (!phoneNumber) {
      setPhoneError("Please enter your phone number.");
      isValid = false;
    } else if (!validatePhoneNumber(phoneNumber)) {
      setPhoneError("Phone number must be exactly 10 digits.");
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (!isValid) return; // Stop if there are validation errors

    const apiData = {
      email: email,
      phoneNumber: phoneNumber,
      phoneNumberExt: "+91",
    };

    try {
      const response = await AuthService.createUser(apiData, deviceId);
      if (response) {
        setPhoneNumber("");
        setEmail("");
        toast.success("Your request submitted successfully..");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // useEffect(() => {
  //   getAuthToken();

  //   return () => {
  //     resetAuthToken();
  //   };
  // }, []);

  // const getAuthToken = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://api.trafyn.info/iam/oauth2/token?grant_type=client_credentials",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           // Additional headers if needed
  //         },
  //       }
  //     );

  //     if (response.ok) {
  //       const data = await response.json();
  //       console.log("data", data);
  //       setAuthToken(data.access_token); // assuming access_token is the key
  //       localStorage.setItem("Authorization", data.access_token);
  //     } else if (response.status === 404) {
  //       console.error("404 Error: Resource not found");
  //       resetAuthToken();
  //     } else {
  //       console.error("Failed to fetch auth token:", response.status);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching auth token:", error);
  //   }
  // };

  // const resetAuthToken = () => {
  //   setAuthToken(null);
  //   localStorage.removeItem("Authorization");
  // };

  const FAQItem = ({ question, answer, index, isInitiallyOpen }) => {
    const [isOpen, setIsOpen] = useState(isInitiallyOpen);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div style={styles.item}>
        <div style={styles.question} onClick={toggleAccordion}>
          <span style={styles.questionText}>{question}</span>
          <img
            src={isOpen ? upArrow : downArrow}
            alt={isOpen ? "Collapse" : "Expand"}
            style={{ height: 18, width: 18 }}
          />
        </div>
        {isOpen && <p style={styles.answer}>{answer}</p>}
      </div>
    );
  };

  const handleAmountReceived = (e) => {
    let amount = e.target.value;
    amount = amount.replace(/[^0-9.]/g, "");

    // Check if the input is empty and clear everything
    if (amount === "") {
      setErrorMessage("");
      setReceivedAmount("");
      return;
    }

    if (amount.includes("--") || amount.includes("++")) {
      setErrorMessage("Please enter a valid amount without -- or ++");
    } else if (
      (amount.match(/\./g) || []).length > 1 ||
      amount.startsWith(".")
    ) {
      setErrorMessage("Please enter a valid amount");
    } else {
      const parts = amount.split(".");
      if (parts.length > 1 && parts[1].length > 2) {
        amount = `${parts[0]}.${parts[1].slice(0, 2)}`;
      }

      setErrorMessage("");
      if (amount && amount !== "0") {
        setReceivedAmount(amount);
      }
    }
  };
  const handleInputChange = (e) => {
    let amount = e.target.value;
    amount = amount.replace(/[^0-9.]/g, "");

    if (amount === "") {
      // Clear the error message and set amount to an empty string when input is cleared
      setErrorMessage("");
      setInvoiceAmount("");
      return;
    }

    if (amount.includes("--") || amount.includes("++")) {
      setErrorMessage("Please enter a valid amount without -- or ++");
    } else if ((amount.match(/\./g) || []).length > 1 || amount.startsWith(".")) {
      setErrorMessage("Please enter a valid amount");
    } else {
      const parts = amount.split(".");
      if (parts.length > 1 && parts[1].length > 2) {
        amount = `${parts[0]}.${parts[1].slice(0, 2)}`;
      }

      setErrorMessage("");
      if (amount && amount !== "0") {
        setInvoiceAmount(amount);
      }
    }
  };
  const navigateToTabOne = () => {
    handleTabChange(1);
    setInvoiceAmount("");
    setReceivedAmount("");
    setMobileNumber("");
    setSelectedDate(null);
  };

  const handleBankNameChange = (e) => {
    const selectedBank = e?.target?.value;
    setBankName(selectedBank);
  };


  const submitCalculate = async () => {
    let isValid = true;
    try {
      if (!isValid) return;
      const calculateData = {
        sourceCurrency: "USD",
        targetCurrency: "INR",
        sourceAmount: parseInt(invoiceAmount),
        targerAmount: parseInt(receivedAmount),
        transactionDate: formatDate(selectedDate),
        phoneNumberExt: "+91",
        phoneNumber: mobileNumber,
      };
      const calcuateDataResponse = await AuthService.missedSavings(
        calculateData,
        deviceId
      );
      setCalculateMissionData(calcuateDataResponse);
      localStorage.setItem("deviceId", deviceId);
      handleTabChange(2);
    } catch (error) {
      console.log("API Error", error);
    }
  };

  const createUpdateUser = async () => {
    let isValid = true;
    try {
      if (!isValid) return;
      const userCreateData = {
        name: fullName,
        email: "",
        companyName: companyName,
        phoneNumberExt: "+91",
        phoneNumber: mobileNumber,
        bankId: "",
        bankName: bankName,
      };
      const createUserResponse = await AuthService.createUser(
        userCreateData,
        deviceId
      );
      setCreateUserData(createUserResponse);
      localStorage.setItem("deviceId", deviceId);
      handleTabChange(4);
    } catch (error) {
      console.log("API Error", error);
    }
  };

  const currencyConverted = async () => {
    try {
      const currencyConvertedData = {
        source: "USD",
        target: "INR",
        value: 1,
      };
      const currencyDataResponse = await AuthService.currencyConverted(
        currencyConvertedData
      );
      setCurrencyValue(currencyDataResponse?.convertedValue?.toFixed(2));
    } catch (error) {
      console.log("API Error", error);
    }
  };

  useEffect(() => {
    currencyConverted();
    getDeviceId();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsButtonDisabled(!entry.isIntersecting); // Disable button when section is not visible
        });
      },
      { threshold: 0.1 }
    );

    if (calculatorRef.current) {
      observer.observe(calculatorRef.current);
    }

    return () => {
      if (calculatorRef.current) {
        observer.unobserve(calculatorRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setFavicon(PaymentFavicon);

    return () => setFavicon(PaymentFavicon);
  }, []);

  const isButtonEnabled =
    invoiceAmount &&
    receivedAmount &&
    selectedDate &&
    mobileNumber &&
    isValidPhnNumber;
  const isCreateButtonEnabled = fullName && companyName && bankName;

  const scrollToCalculate = () => {
    scroller.scrollTo("calculateSection", {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const Header = () => {
    return (
      <div className="fixed-header">
        <div className="container">
          <img
            src={novapayLogo}
            width={200}
            alt="novapayLogo"
            className="novapaylogo-position"
          />
        </div>
      </div>
    );
  };
  const [isSecondScrollable, setIsSecondScrollable] = useState(false);
  const firstSectionRef = useRef(null);
  const thirdSectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry], observer) => {
        if (entry.target === firstSectionRef.current) {
          // If the first section is not in view, enable scroll for the second section
          setIsSecondScrollable(!entry.isIntersecting);
        }
        if (entry.target === thirdSectionRef.current) {
          // If the third section is not in view, enable scroll for the second section
          setIsSecondScrollable(!entry.isIntersecting);
        }
      },
      { threshold: 0.1 }
    );

    if (firstSectionRef.current) observer.observe(firstSectionRef.current);
    if (thirdSectionRef.current) observer.observe(thirdSectionRef.current);

    return () => {
      if (firstSectionRef.current) observer.unobserve(firstSectionRef.current);
      if (thirdSectionRef.current) observer.unobserve(thirdSectionRef.current);
    };
  }, []);
  return (
    <div>
      <SEO
        title="Top FX Rates with JP Morgan"
        description="Cut FX fees by 50%, zero account fees—better rates with JP Morgan for exporters."
        // keywords="keyword1, keyword2, keyword3"
        url="https://thesnippets.info/forex"
        image="https://thesnippets.info/images/logo.png"
      />

      <div className="noscroll-x">
        {/* // Header section */}
        <div className="backgroud-gradient-image" ref={firstSectionRef}>
          <Header />
          <div className="responsive-div"></div>
          <div className="container">
            <div className="row header-section">
              <div className="col-12 col-lg-6 col-md-12 left-part">
                <div className="main-part">
                  <h1 className="font-size-56 text-light">
                    Improved <br /> Inward Remittance <br /> for Indian
                    Exporters
                  </h1>
                  <h2 className="sub-content">
                    <span className="position-top-4">SAVE UP TO </span>{" "}
                    <span className="petit-font mx-1">50%</span>{" "}
                    <span className="position-top-4">ON TRANSFER FEE </span>
                  </h2>
                </div>
                <div className="">
                  <button
                    className="saving-now-button"
                    onClick={handleScrollTocalculator}
                  >
                    Calculate Missed Savings
                  </button>
                  <p
                    className="text-size-16 text-light"
                    style={{ fontWeight: 600 }}
                  >
                    {" "}
                    <img src={starWhite} alt="starLogo" className="starLogo" />
                    <span style={{ position: "relative", top: 2 }}>
                      500+ exporters have calculated their savings
                    </span>
                    <img src={starWhite} alt="starLogo" className="starLogo" />
                  </p>
                  <p
                    className="text-size-16 text-light mt-4"
                    style={{ fontWeight: 600 }}
                  >
                    0 joining and annual fee
                  </p>
                  <div className="mt-4 d-flex">
                    <p className="text-size-16 text-light mb-0">Powered by </p>
                    <img
                      src={jpMorganLogo}
                      alt="Logo"
                      width={"120px"}
                      className=""
                      style={{ paddingLeft: 6 }}
                    />
                  </div>
                  <div className="d-flex mt-2">
                    <p
                      className="text-size-16 text-light mb-0"
                      style={{ paddingTop: 15, marginRight: 5 }}
                    >
                      Compliant with
                    </p>
                    {/* <RBILOGO /> */}
                    {/* <img src={RBINEWLOGO} alt="RBI Logo" className="rbi-logo-new" /> */}
                    <img src={RBILOGO} alt="RBI Logo" className="rbi-logo" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 right-part-v2 justify-content-center">
                <img src={paymentBannerImg} className="paymentImg" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* // Video player section */}
        <div className="section">
          <p className="font-large text-center">How does it work?</p>
          <div className="video-section">
            <video className="video-height" controls muted preload="auto">
              <source src={landingVideo} type="video/mp4" />
            </video>
          </div>
        </div>
        {/* // calculation section */}
        <Element name="calculateSection">
          <div
            className="payment-second-sectionV2 scroll-behaviour"
            style={{
              overflowY: isSecondScrollable ? "scroll" : "hidden",
              height: "100vh",
              scrollbarWidth: "none",
            }}
          >
            <div className="container">
              <div className="row row-reverse">
                <div className="col-12 col-lg-6 col-md-6">
                  <div className="section-div container custom-font-div">
                    <h1 className="gCFont mb-1">Same Day Account Setup</h1>
                    <p className="font-large">JPMorgan FX Account</p>
                    <p className="font-size-20">
                      A name that your clients already trust, set up
                    </p>
                    <p className="font-size-20">
                      {" "}
                      your account in just 24 hours
                    </p>
                    <button
                      className="btn-blue-bg"
                      onClick={handleScrollToBottom}
                    >
                      Get in Touch With Us
                      <img
                        src={rightArrow}
                        alt="arrow-icon"
                        className="arrow-btn"
                      />
                    </button>
                  </div>
                  <div className="section-div container custom-font-div">
                    <h1 className="gCFont">Transfer Fee</h1>
                    <p className="font-large">50% Lower Fees</p>
                    <p className="font-size-20">
                      Save on every International Transfer with
                    </p>
                    <p className="font-size-20"> low transfer fees.</p>
                    <button
                      className="btn-blue-bg"
                      onClick={handleScrollToBottom}
                    >
                      Get in Touch With Us
                      <img
                        src={rightArrow}
                        alt="arrow-icon"
                        className="arrow-btn"
                      />
                    </button>
                  </div>
                  <div className="section-div container custom-font-div">
                    <h1 className="gCFont">BRC Process</h1>
                    <p className="font-large">Hasel free BRC process</p>
                    <p className="font-size-20">
                      Get free RBI approved FIRA document to
                    </p>
                    <p className="font-size-20">submit for BRC process</p>
                    <button
                      className="btn-blue-bg"
                      onClick={handleScrollToBottom}
                    >
                      Get in Touch With Us
                      <img
                        src={rightArrow}
                        alt="arrow-icon"
                        className="arrow-btn"
                      />
                    </button>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 10,
                      }}
                    >
                      <a href={FIRAPdf} className="a-tag" target="_blank">
                        View Sample FIRA document
                        <img src={linkArrow} alt="icon" height={22} />
                      </a>
                      <a
                        href="https://rbi.org.in/Scripts/BS_CircularIndexDisplay.aspx?Id=7531"
                        className="a-tag"
                        target="_blank"
                      >
                        View RBI Circular
                        <img src={linkArrow} alt="icon" height={22} />
                      </a>
                      <a
                        href="https://fedai.org.in/pdf/Circular%20Letter%20No_16_2016_17_OCT_FAQs_EDPMS.pdf"
                        className="a-tag"
                        target="_blank"
                      >
                        View FEDAI Circular
                        <img src={linkArrow} alt="icon" height={22} />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-md-6 col-lg-6"
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "end",
                  }}
                  ref={calculatorRef}
                >
                  <div
                    className="white-card card-width-400 "
                    style={{ position: "sticky", top: "10%" }}
                  >
                    <div className="d-flex snippet-justify-center text-center">
                      <img src={starIcon} width={20} />
                      <h6 className="gradient-text-small p-2 position-top-4">
                        Missed Savings Calculator
                      </h6>
                      <img src={starIcon} width={20} />
                    </div>

                    {currentTab === 1 && (
                      <div className="">
                        <div className="my-2">
                          <div
                            className="center-align"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div className="">
                              <p className="text-size-16 text-dark border-gray-circle mb-0">
                                01
                              </p>
                            </div>
                            <div
                              className="border-top-line"
                              style={{
                                backgroundColor: "gray",
                              }}
                            ></div>
                            <p className="text-size-16 text-gray border-gray-circle mb-0">
                              02
                            </p>
                          </div>
                          <div
                            className="center-align mt-1"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="text-size-12 snippet-pl-20 text-dark mb-0">
                              Your Savings
                            </p>
                            <p className="text-size-12 text-dark snippet-pr-20 mb-0">
                              Get Started
                            </p>
                          </div>
                        </div>
                        <div className="d-flex snippet-justify-center">
                          <div className="w-100">
                            <div className="padding-12px">
                              <div className="mb-3">
                                <h6 className="text-size-16-semi text-dark mb-2 font-weight-600">
                                  Payment Received Date for a Past Trade
                                </h6>
                                <div className="">
                                  <div className="d-flex snippet-border-gray pointer">
                                    <DatePicker
                                      selected={selectedDate}
                                      onChange={handleDateChange}
                                      value={selectedDate}
                                      minDate={new Date("2019-01-01")}
                                      dateFormat="dd-MM-yyyy"
                                      maxDate={maxDate}
                                      style={{
                                        border: "none",
                                        pointerEvents: "none",
                                      }}
                                      className="ct-input-box ct-pointer"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <h6 className="text-size-16-semi text-dark mb-2 font-weight-600">
                                  Past trade invoice amount
                                </h6>
                                <div className="">
                                  <div className="d-flex snippet-border-gray-input">
                                    <div className="d-flex snippet-padding-top-17">
                                      <img
                                        src={USFlag}
                                        width={20}
                                        height={20}
                                        style={{ borderRadius: 50 }}
                                        className="my-auto"
                                      />
                                      <p
                                        className="text-size-14 text-dark my-auto"
                                        style={{
                                          paddingLeft: 7,
                                          fontWeight: 600,
                                        }}
                                      >
                                        {" "}
                                        USD($)
                                      </p>
                                    </div>
                                    <input
                                      type="text" // Change to 'text' to allow precise control
                                      className="form-control custom-placeholder w-90"
                                      onChange={handleInputChange}
                                      value={invoiceAmount}
                                      placeholder="Enter Invoice Amount"
                                      maxLength={5} // Works with type="text", not "number"
                                      onInput={(e) => {
                                        if (e.target.value.length > 5) e.target.value = e.target.value.slice(0, 5);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <h6 className="text-size-16-semi text-dark mb-2 font-weight-600">
                                  INR received for that invoice
                                </h6>
                                <div className="">
                                  <div className="d-flex snippet-border-gray-input">
                                    <div className="d-flex snippet-padding-top-17">
                                      <img
                                        src={flagIndia}
                                        width={20}
                                        height={20}
                                        style={{ borderRadius: 50 }}
                                        className="my-auto"
                                      />
                                      <p
                                        className="text-size-14 text-dark my-auto"
                                        style={{
                                          paddingLeft: 7,
                                          fontWeight: 600,
                                        }}
                                      >
                                        {" "}
                                        INR(₹)
                                      </p>
                                    </div>
                                    <input
                                      type="number"
                                      className="form-control custom-placeholder w-90"
                                      onChange={handleAmountReceived}
                                      value={receivedAmount}
                                      placeholder="Enter Amount Received"
                                      maxLength={7} // Works with type="text", not "number"
                                      onInput={(e) => {
                                        if (e.target.value.length > 7) e.target.value = e.target.value.slice(0, 7);
                                      }}
                                    />
                                  </div>
                                </div>
                                <p className="note-size-10">Enter this amount as per your bank statement</p>
                              </div>
                              <div className="mb-3">
                                <h6 className="text-size-16-semi text-dark mb-2 font-weight-600">
                                  Mobile Number
                                </h6>
                                <div className="">
                                  <div className="snippet-border-gray-input d-flex">
                                    <span className="snippet-country-code my-auto"
                                      style={{ fontWeight: 600 }}>
                                      +91
                                    </span>
                                    <input
                                      type="number"
                                      value={mobileNumber}
                                      onChange={handleMobileNumber}
                                      className="form-control custom-placeholder w-90"
                                      placeholder="Enter 10 digits mobile number"
                                    />
                                  </div>
                                  {!isValidPhnNumber && (
                                    <span className="error-message">
                                      {errorMessage}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="snippet-bg-voilet mt-3">
                                <div className="d-flex snippet-space-between">
                                  <p className="text-size-16-semi text-dark mb-1">
                                    Today’s Mid Market Rate
                                  </p>
                                  <p className="text-size-16-semi text-dark mb-1">
                                    1USD={currencyValue} INR
                                  </p>
                                </div>
                              </div>
                              {/* {isButtonEnabled && ( */}
                              <button
                                className="saving-now-button-full"
                                onClick={submitCalculate}
                                disabled={!isButtonEnabled}
                                id="btn_calculator"
                              >
                                Calculate Missed Savings
                              </button>
                              <div className=" d-flex snippet-justify-center">
                                <p className="text-size-12 text-dark mb-0">
                                  Powered by{" "}
                                </p>
                                <img
                                  src={jpMorganLogoDark}
                                  alt="Logo"
                                  width={"110px"}
                                  className="text-dark"
                                  style={{ paddingLeft: 6 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {currentTab === 2 && (
                      <div className="">
                        <div className="my-2">
                          <div
                            className="center-align"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div className="">
                              <CheckCircleIcon
                                style={{ fontSize: 50, color: "#0052B4" }}
                              />
                            </div>
                            <div
                              className="border-top-line"
                              style={{
                                width: "280px",
                                backgroundColor: "gray",
                              }}
                            ></div>
                            <p className="text-size-16 text-gray border-gray-circle mb-0">
                              02
                            </p>
                          </div>
                          <div
                            className="center-align pt-2 snippet-pl-20"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="text-size-12 text-primary mb-0">
                              Your Savings
                            </p>
                            <p className="text-size-12 text-dark snippet-pr-20 mb-0">
                              Get Started
                            </p>
                          </div>
                        </div>
                        <div className="d-flex snippet-justify-center">
                          <div className="">
                            <div className="p-2">
                              <div className="snippet-border-bottom-gray">
                                <div className="d-flex snippet-space-between">
                                  <p className="cal-amount-font text-dark mb-2 font-weight-600">
                                    Invoice Amount
                                  </p>
                                  <p className="cal-amount-font text-dark mb-2 font-weight-600">
                                    {calculateMissionData?.sourceAmount?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                                  </p>
                                </div>
                                <div className="d-flex snippet-space-between">
                                  <p className="cal-amount-font text-dark mb-2 font-weight-600">
                                    Amount Received
                                  </p>
                                  <p className="cal-amount-font text-dark mb-2 font-weight-600">
                                    {calculateMissionData?.targetAmount?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} INR
                                  </p>
                                </div>
                                <div className="d-flex snippet-space-between">
                                  <p className="cal-amount-font text-dark font-weight-600">
                                    Date of Transaction
                                  </p>
                                  <p className="cal-amount-font text-dark font-weight-600">
                                    {formatDatevalue(
                                      calculateMissionData?.transactionDate
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="mt-3">
                                <div className="d-flex snippet-space-between">
                                  <div className="">
                                    <p className="text-light mb-0">
                                      <span className="grandient-badge ">
                                        JP Morgan Account
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex snippet-space-between">
                                  <div className="text-center snippet-bg-voilet-square">
                                    <p className="text-size-16 text-dark mb-1">
                                      Mid Market Rate
                                    </p>
                                    <p className="text-size-16 text-dark mb-1">
                                      on{" "}
                                      {formatDatevalue(
                                        calculateMissionData?.transactionDate
                                      )}
                                    </p>
                                    <p className="text-size-21 text-dark mb-1">
                                      1USD ={" "}
                                      {calculateMissionData?.conversionRate?.toFixed(
                                        2
                                      )}{" "}
                                      INR
                                    </p>
                                  </div>
                                  {/* {calculateMissionData?.transactionConversionRate <=
                                  calculateMissionData?.conversionRate ? ( */}
                                  <>
                                    <div className="snippet-align-content-center">
                                      <img src={ImageVss} alt="vs" />
                                    </div>
                                    <div
                                      className={`text-center snippet-align-content-center ${calculateMissionData?.transactionConversionRate <=
                                        calculateMissionData?.conversionRate
                                        ? "snippet-bg-gray-square"
                                        : "snippet-bg-red-square"
                                        }`}
                                    >
                                      {calculateMissionData?.transactionConversionRate <=
                                        calculateMissionData?.conversionRate ? (
                                        <div className="">
                                          <p
                                            className={` text-size-16 mb-1 ${calculateMissionData?.transactionConversionRate <=
                                              calculateMissionData?.conversionRate
                                              ? "text-gray-dark"
                                              : "snippet-text-dark"
                                              }`}
                                          >
                                            Bank’s effective FX rate
                                          </p>
                                          <p
                                            className={` text-size-16 mb-1 ${calculateMissionData?.transactionConversionRate <=
                                              calculateMissionData?.conversionRate
                                              ? "text-gray-dark"
                                              : "snippet-text-dark"
                                              }`}
                                          >
                                            on{" "}
                                            {formatDatevalue(
                                              calculateMissionData?.transactionDate
                                            )}
                                          </p>
                                          <p
                                            className={` text-size-16-normal mb-1 ${calculateMissionData?.transactionConversionRate <=
                                              calculateMissionData?.conversionRate
                                              ? "text-gray-dark"
                                              : "snippet-text-dark"
                                              }`}
                                          >
                                            1USD ={" "}
                                            {calculateMissionData?.transactionConversionRate?.toFixed(
                                              2
                                            )}{" "}
                                            INR
                                          </p>
                                        </div>
                                      ) : (
                                        <div
                                          className="snippet-width-200"
                                          style={{ alignContent: "center" }}
                                        >
                                          <p
                                            className={` text-size-16 mb-1 ${calculateMissionData?.transactionConversionRate <=
                                              calculateMissionData?.conversionRate
                                              ? "text-gray-dark"
                                              : "snippet-text-dark"
                                              }`}
                                          >
                                            Wrong Input
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </>
                                  {/* ) : ( */}
                                  {/* <>
                                    <div className="snippet-width-200">

                                    </div>
                                  </>
                                )} */}
                                </div>
                              </div>

                              {calculateMissionData?.transactionConversionRate <=
                                calculateMissionData?.conversionRate ? (
                                <div className="">
                                  <div className="snippet-bg-yellow mt-3">
                                    <p
                                      className="text-size-16 text-dark mb-2"
                                      style={{ fontWeight: 600 }}
                                    >
                                      🤯 Oh no! You could have saved
                                    </p>
                                    <div className="d-flex snippet-space-between">
                                      <p className="text-size-20 text-dark mb-1">
                                        ₹{" "}
                                        {calculateMissionData?.totalMissedAmount?.toFixed(
                                          2
                                        )}
                                      </p>
                                      <div className="d-flex">
                                        <img
                                          src={flagIndia}
                                          width={20}
                                          height={20}
                                          style={{ borderRadius: 50 }}
                                        />
                                        <p
                                          className="text-size-16 text-dark mb-0"
                                          style={{
                                            paddingLeft: 7,
                                            fontWeight: 600,
                                          }}
                                        >
                                          {" "}
                                          INR
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    className="saving-now-button-full mt-3 py-3"
                                    onClick={() => {
                                      handleTabChange(3);
                                    }}
                                    id="btn_savingNow"
                                  >
                                    Start Saving Now
                                  </button>
                                </div>
                              ) : (
                                <div className="">
                                  <div className="snippet-bg-red mt-4">
                                    <p
                                      className="text-size-16 text-dark mb-0"
                                      style={{ fontWeight: 400 }}
                                    >
                                      Please enter the correct USD/INR value
                                    </p>
                                  </div>
                                  <div className="text-center">
                                    <button
                                      className="retry-now-button-full mt-3 py-3"
                                      onClick={navigateToTabOne}
                                      id="btn_retry"
                                    >
                                      Retry
                                    </button>
                                  </div>
                                </div>
                              )}
                              <div className=" d-flex snippet-justify-center">
                                <p className="text-size-12 text-dark mb-0">
                                  Powered by{" "}
                                </p>
                                <img
                                  src={jpMorganLogoDark}
                                  alt="Logo"
                                  width={"110px"}
                                  className="text-dark"
                                  style={{ paddingLeft: 6 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {currentTab === 3 && (
                      <div className="w-100">
                        <div className="my-3">
                          <div
                            className="center-align"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div className="">
                              <CheckCircleIcon
                                style={{ fontSize: 50, color: "#0052B4" }}
                              />
                            </div>
                            <div
                              className="border-top-line"
                              style={{
                                width: "280px",
                                backgroundColor: "gray",
                              }}
                            ></div>
                            <p className="text-size-16 text-dark border-gray-circle mb-0">
                              02
                            </p>
                          </div>
                          <div
                            className="center-align pt-2 snippet-pl-20"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="text-size-12 text-primary">
                              Your Savings
                            </p>
                            <p className="text-size-12 text-dark snippet-pr-20">
                              Get Started
                            </p>
                          </div>
                        </div>
                        <div className="d-flex snippet-justify-center">
                          <div className="w-100">
                            <div className="p-3">
                              <div className="mb-3">
                                <h6 className="text-size-16-semi text-dark mb-1">
                                  Name
                                </h6>
                                <div className="">
                                  <div className="snippet-border-gray-input">
                                    <input
                                      type="text"
                                      value={fullName}
                                      onChange={handleNameChange}
                                      className="form-control custom-placeholder w-90"
                                      placeholder="Enter Name"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <h6 className="text-size-16-semi text-dark mb-1">
                                  Company Name
                                </h6>
                                <div className="">
                                  <div className="snippet-border-gray-input">
                                    <input
                                      type="text"
                                      value={companyName}
                                      onChange={handleCompanyName}
                                      className="form-control custom-placeholder w-90"
                                      placeholder="Enter Company Name"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="mb-3">
                                <h6 className="text-size-16-semi text-dark mb-1">
                                  Bank Name
                                </h6>
                                <div className="">
                                  <div className="snippet-border-gray-input">
                                    <Select
                                      style={{ padding: 0 }}
                                      className="snippet-input-box"
                                      value={bankName}
                                      onChange={handleBankNameChange}
                                      displayEmpty
                                      renderValue={(selected) =>
                                        selected ? selected : "Select Bank Name"
                                      }
                                    >
                                      <MenuItem value="">Select Bank</MenuItem>
                                      {bankList?.map((bank) => (
                                        <MenuItem
                                          key={bank?._id}
                                          value={bank?.name}
                                        >
                                          {bank?.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                              {/* {isCreateButtonEnabled && ( */}
                              <button
                                className="saving-now-button-full mt-3 py-3"
                                onClick={createUpdateUser}
                                disabled={!isCreateButtonEnabled}
                                id="btn_finalSubmit"
                              >
                                Submit Details
                              </button>
                              {/* )} */}
                              <div className=" d-flex snippet-justify-center">
                                <p className="text-size-12 text-dark mb-0">
                                  Powered by{" "}
                                </p>
                                <img
                                  src={jpMorganLogoDark}
                                  alt="Logo"
                                  width={"110px"}
                                  className="text-dark"
                                  style={{ paddingLeft: 6 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {currentTab === 4 && (
                      <div className="" id="success_section">
                        <div className="my-3">
                          <div
                            className="center-align"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div className="">
                              <CheckCircleIcon
                                style={{ fontSize: 50, color: "#0052B4" }}
                              />
                            </div>
                            <div
                              className="border-top-line"
                              style={{
                                width: "280px",
                                backgroundColor: "gray",
                              }}
                            ></div>
                            <CheckCircleIcon
                              style={{ fontSize: 50, color: "#0052B4" }}
                            />
                          </div>
                          <div
                            className="center-align pt-2 snippet-pl-20"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              // alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="text-size-12 text-primary">
                              Your Savings
                            </p>
                            <p className="text-size-12 text-primary snippet-pr-20">
                              Get Started
                            </p>
                          </div>
                        </div>
                        <div className="d-flex snippet-justify-center">
                          <div className="">
                            <div className="p-3">
                              <div className="text-center">
                                <img
                                  src={successGif}
                                  alt="successGif"
                                  width={200}
                                  height={200}
                                />
                                <div className="my-3">
                                  <p className="text-size-16 text-dark mb-1">
                                    Download ‘Snippets’ to get summarised
                                    updates of the import-export market.
                                  </p>
                                </div>
                                <div className="snippet-display-flex web-justify-center mobile-d-flex-center">
                                  <div className="mr-15">
                                    <a
                                      href="https://play.google.com/store/apps/details?id=com.ninja.snippets"
                                      target="_blank"
                                    >
                                      <img src={playStoreImg} width="150px" />{" "}
                                    </a>
                                  </div>
                                  <div className="">
                                    <a
                                      href="https://apps.apple.com/in/app/snippets-global-trade-news/id6478856321"
                                      target="_blank"
                                    >
                                      <img src={appStoreImg} width="150px" />{" "}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className=" d-flex snippet-justify-center">
                                <p className="text-size-12 text-dark mb-0">
                                  Powered by{" "}
                                </p>
                                <img
                                  src={jpMorganLogoDark}
                                  alt="Logo"
                                  width={"110px"}
                                  className="text-dark"
                                  style={{ paddingLeft: 6 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Element>

        {/* // Discover Benefits section */}
        <div
          className="row px-2"
          ref={thirdSectionRef}
          style={{
            backgroundImage: `url(${tablebg})`,
            backgroundSize: "cover",
            backgroundColor: "#fff",
          }}
        >
          <div className="col-12 col-lg-12 col-md-12">
            <div className="section">
              <div className="snippet-width-90">
                <div className="container">
                  <p className="gCFont mb-1">Start Saving Now !</p>
                  <p className="font-large">Discover Benefits</p>
                </div>
                <div className="table-section">
                  <table className="table table-bordered container">
                    <thead>
                      <tr>
                        <th className="tableHead">
                          <div className="web-d-flex align-items-center justify-content-start snippet-text-align-center">
                            <img
                              src={jpMorganLogo}
                              alt="icon"
                              className="jp-morgan-logo"
                              style={{ marginTop: 4 }}
                            />
                            <span>
                              <p
                                className="fx-font-size-24 text-light"
                                style={{
                                  marginBottom: 0,
                                  margin: "0px 10px",
                                }}
                              >
                                FX Account
                              </p>
                            </span>
                          </div>
                        </th>
                        <th
                          className="tableHead text-left fx-font-size-24 text-light"
                          style={{ verticalAlign: "middle" }}
                        >
                          Other Indian Banks Accounts
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="borderBottom snippet-bg-white px-2">
                        <td className="d-flex snippet-bg-white">
                          <img
                            src={circleTick}
                            alt="circleTick"
                            height={25}
                            width={25}
                          />
                          <p className="px-2 mb-0 helvetica-font font-size-20">
                            Globally Recognised Bank
                          </p>
                        </td>
                        <td className="align-middle text-left">
                          <p className="helvetica-font mb-0 font-size-20">
                            Unfamiliar Indian bank details
                          </p>
                        </td>
                      </tr>
                      <tr className="borderBottom">
                        <td className="d-flex snippet-bg-white">
                          <img
                            src={circleTick}
                            alt="circleTick"
                            height={25}
                            width={25}
                          />
                          <p className="px-2 mb-0 helvetica-font font-size-20">
                            Instant Client Trust
                          </p>
                        </td>
                        <td className="align-middle text-left">
                          <p className="helvetica-font mb-0 font-size-20">
                            Extra verification needed
                          </p>
                        </td>
                      </tr>
                      <tr className="borderBottom">
                        <td className="d-flex snippet-bg-white">
                          <img
                            src={circleTick}
                            alt="circleTick"
                            height={25}
                            width={25}
                          />
                          <p className="px-2 mb-0 snippet-bg-white helvetica-font font-size-20">
                            50% Lower Fee
                          </p>
                        </td>
                        <td className="align-middle text-left">
                          <p className="helvetica-font mb-0 font-size-20">
                            Higher fees with hidden charges
                          </p>
                        </td>
                      </tr>
                      <tr className="borderBottom snippet-bg-white">
                        <td className="d-flex snippet-bg-white">
                          <img
                            src={circleTick}
                            alt="circleTick"
                            height={25}
                            width={25}
                          />
                          <p className="px-2 mb-0 snippet-bg-white helvetica-font font-size-20">
                            Instant FIRA for FREE
                          </p>
                        </td>
                        <td className="align-middle text-left">
                          <p className="helvetica-font mb-0 font-size-20">
                            May require multiple follow ups
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex snippet-bg-white">
                          <img
                            src={circleTick}
                            alt="circleTick"
                            height={25}
                            width={25}
                          />
                          <p className="px-2 mb-0 helvetica-font font-size-20">
                            Simple US Account Details
                          </p>
                        </td>
                        <td className="align-middle text-left">
                          <p className="helvetica-font mb-0 font-size-20">
                            Complex SWIFT/IFSC codes
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* // Security section */}
        <div className="" style={{ backgroundColor: "#F8F8FB" }}>
          <div className="container pt-5">
            <div
              className="cardbg"
              style={{
                backgroundImage: `url(${worldPng})`,
                backgroundSize: "cover",
              }}
            >
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <p className="font-size-56 text-light mb-1">
                    Your Security <br /> is our{" "}
                    <span className="font-gradient-text">Top Priority</span>
                  </p>

                  <p className="font-size-30">
                    Discover our rigorous security <br /> measures
                  </p>
                </div>

                <div className="col-12 col-md-5 col-lg-5">
                  <div className="snippet-mbl-img-left">
                    <img
                      src={sheild}
                      alt="sheild"
                      className="snippet-sheild-img"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "2px",
                  backgroundColor: "white",
                  margin: "30px 0",
                }}
              ></div>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 security-measure-alignment">
                  <img src={bankLogo} className="logoStyle" />
                  <p className="font-size-24 text-light">
                    RBI compliant KYC & KYB processes
                  </p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 security-measure-alignment">
                  <img src={ContactLogo} className="logoStyle" />
                  <p className="font-size-24 text-light">
                    FDIC insured JP Morgan Forex Account
                  </p>
                </div>
                <div className="col-12 col-md-4 col-lg-4 security-measure-alignment">
                  <img src={fileLogo} className="logoStyle" />
                  <p className="font-size-24 text-light">
                    FIRA compliant with FEDIA & FEAM regulations
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="section bg-map-image"
            style={{
              backgroundImage: `url(${blueWorld})`,
              backgroundSize: "cover",
              color: "#000",
            }}
          >
            <div className="container">
              <div className="row section-div">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className=" container custom-font-div">
                    <p className="font-size-48 mb-2 pb-1">
                      3 steps to lower fees <br />
                      with more trust
                    </p>
                    <p className="snippets-font-size-28 mb-0 snippets-font-weight-500">
                      Set up your account in
                    </p>
                    <p className="font-gradient-text font-size-34">
                      5 minutes!
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="">
                    <div className="stepCard">
                      <div className="cardContent">
                        <span className="leftContent my-auto">Sign Up</span>
                        <span className="gCFont" style={{ fontSize: 36 }}>
                          1
                        </span>
                      </div>
                      <p className="steps-description ">
                        Complete our 5 minute KYC process
                      </p>
                    </div>
                    <div className="stepCard">
                      <div className="cardContent">
                        <span className="leftContent my-auto">
                          Get Your Account
                        </span>
                        <span className="gCFont" style={{ fontSize: 40 }}>
                          2
                        </span>
                      </div>
                      <p className="steps-description">
                        Get your JP Morgan account details in just 24 hours
                      </p>
                    </div>
                    <div className="stepCard">
                      <div className="cardContent">
                        <span className="leftContent my-auto">
                          Start Saving
                        </span>
                        <span className="gCFont" style={{ fontSize: 36 }}>
                          3
                        </span>
                      </div>
                      <p className="steps-description">
                        Accept payments at half the fees compared to other banks
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container custom-font-div my-4">
            <div className="row">
              <div className="col-12 col-lg-6 col-md-6">
                <h1 className="gCFont">Start Saving Now !</h1>
                <p className="font-large">Stop Overpaying</p>
                <p className="font-size-20 mb-0">
                  Join the smart exporters who have already switched to JP
                  Morgan Chase Forex Account with
                </p>
                <p className="font-size-20"> low transfer fees.</p>
                <button className="btn-blue-bg" onClick={handleScrollToBottom}>
                  Get in Touch With Us
                  <img
                    src={rightArrow}
                    alt="arrow-icon"
                    className="arrow-btn"
                  />
                </button>
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <div
                  className="testimonial-card-d-flex"
                  style={{ alignItems: "stretch" }}
                >
                  <div className="testimonial-card text-center">
                    {/* <div className="text-center">
                      <img className="avatarIcon" src={profileAvatar} alt="" />
                  </div> */}
                    <div className="card-content">
                      <p className="Name">Rakshitha Flowers</p>
                      <p className="traderType">Chennai</p>
                      <p className="comments">
                        “ I am now receiving payments from my Malaysian
                        customers at a 50% cheaper rate compared to the usual
                        bank's rate. ”
                      </p>
                    </div>
                  </div>
                  <div className="testimonial-card text-center">
                    {/* <div className="text-center">
                      <img className="avatarIcon" src={profileAvatar} alt="" />
                  </div> */}
                    <div className="card-content">
                      <p className="Name">Global Harvest</p>
                      <p className="traderType">Bengaluru</p>
                      <p className="comments">
                        “ After we started sharing JP Morgan account details
                        with our customers, we started gaining trust from our
                        overseas customers quickly then ever before. ”
                      </p>
                    </div>
                  </div>
                  <div className="testimonial-card text-center">
                    {/* <div className="text-center">
                      <img className="avatarIcon" src={profileAvatar} alt="" />
                  </div> */}
                    <div className="card-content">
                      <p className="Name">ShivKrupa IMPEX</p>
                      <p className="traderType">Mumbai</p>
                      <p className="comments">
                        “ It's cheaper and easier to receive money from our
                        customers, that too without any follow-ups that banks
                        make us do. ”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-div container custom-font-div">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="container">
                  <p className="gCFont mb-0">FAQ's</p>
                  <p className="font-large mb-0">
                    Frequently Asked <br />
                    Questions
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div style={styles.container} className="container">
                  {faqs.map((faq, index) => (
                    <FAQItem
                      key={index}
                      question={faq.question}
                      answer={faq.answer}
                      index={index}
                      isInitiallyOpen={index === 0} // Set first item to open initially
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* // Get in Touch With Us section */}
        <div ref={footerRef} className="footer-background-gradient-image">
          <div className="container footer-sec">
            <div className="row borderBottom">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="">
                  <p className="text-light mb-1 text-size-20">
                    Get in Touch With Us
                  </p>
                  <p className="font-large mb-0 text-light">
                    Apply for your JP <br /> Morgan Forex Account
                  </p>
                  <p className="font-size-20 text-light mb-0">
                    Submit your details and our representative <br /> will get
                    back to you.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                {/* <div className="phone-input-container">
                    <label className="label">Enter Your Email Address</label>
                    <input type="email" className="input" />

                    <label className="label">Enter Your Phone Number</label>
                    <div className="phone-input-container">
                      <span className="country-code">+91</span>
                      <input type="tel" className="input phone-input" />
                      <button className="submit-button my-4">Submit</button>
                    </div>
                  </div> */}
                <div className="phone-input-container">
                  <label className="label">Enter Your Email Address</label>
                  <input
                    type="email"
                    className="input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* Show email error below the input */}
                  {emailError && (
                    <p style={{ color: "red", margin: "4px 0" }}>
                      {emailError}
                    </p>
                  )}

                  <label className="label">Enter Your Phone Number</label>
                  <div className="phone-input-container">
                    <span className="country-code">+91</span>
                    <input
                      type="number"
                      className="input phone-input"
                      value={phoneNumber}
                      onChange={(e) => {
                        const input = e.target.value;
                        // Allow empty input or a 10-digit number starting with 9, 8, or 7
                        if (input === "" || /^[9876]\d{0,9}$/.test(input)) {
                          setPhoneNumber(input); // Update only if valid or empty
                        }
                      }}
                    />
                  </div>
                  {/* Show phone error below the input */}
                  {phoneError && (
                    <p style={{ color: "red", margin: "4px 0" }}>
                      {phoneError}
                    </p>
                  )}

                  <button className="submit-button my-4" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="row borderBottom align-item-center space-y">
              <div className="col-12 col-md-6 col-lg-6">
                <p className="font-large mb-0 text-light">
                  Save more with <br /> NovaPay today
                </p>
              </div>
              <div className="col-12 col-md-6 col-lg-6 align-item-center">
                <p className="text-size-24 mb-0 text-light">
                  NovaPay offers Indian exporters a seamless and affordable way
                  to receive international payments. Our user-friendly platform
                  provides access to global infrastructure, ensuring your
                  transactions are secure and efficient.
                </p>
              </div>
            </div>
            <div className="copy-rights">
              <p className="text-size-16 text-light">
                ©{currentYear} All rights reserved.
              </p>
              {/* <img src={rights} height={16} alt="copyrights icon" /> */}
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
      {isButtonDisabled && (
        <button
          className="saving-now-button my-2 floating-button"
          onClick={handleScrollTocalculator}
        >
          Calculate Missed Savings
        </button>
      )}
    </div>
  );
};

const styles = {
  item: {
    borderBottom: "1px solid #e0e0e0",
    padding: "10px 0",
    fontFamily: "Helvetica Neue",
  },
  question: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "500",
    color: "#2a2a2a",
    fontSize: "18px",
    fontFamily: "Helvetica Neue",
  },
  questionText: {
    flex: 1,
  },
  icon: {
    height: 22,
    color: "#1e73e8",
  },
  answer: {
    padding: "10px 0 0",
    color: "#666",
    fontSize: "16px",
  },
};

export default PaymentScreenNew;
